"use strict";

(function e(t, n, r) {
  function s(o, u) {
    if (!n[o]) {
      if (!t[o]) {
        var a = typeof require == "function" && require;if (!u && a) return a(o, !0);if (i) return i(o, !0);var f = new Error("Cannot find module '" + o + "'");throw f.code = "MODULE_NOT_FOUND", f;
      }var l = n[o] = { exports: {} };t[o][0].call(l.exports, function (e) {
        var n = t[o][1][e];return s(n ? n : e);
      }, l, l.exports, e, t, n, r);
    }return n[o].exports;
  }var i = typeof require == "function" && require;for (var o = 0; o < r.length; o++) {
    s(r[o]);
  }return s;
})({ 1: [function (require, module, exports) {
    'use strict';

    var setRootSize = function setRootSize() {
      var root_html = document.documentElement;
      var client_width = root_html.clientWidth;
      var root_size = client_width <= 750 ? client_width / 7.5 : 100;
      root_html.style.fontSize = root_size + 'px';
    };

    window.addEventListener('resize', function () {
      setRootSize();
    });

    setRootSize();
  }, {}] }, {}, [1]);